<script>
import Layout from "../_layouts/auth"


/**
 * Login component
 */
export default {
  components: {
    Layout,
  },
  data() {
    return {
      username: window.localStorage.getItem('loginUsername'),
      password: process.env.NODE_ENV === 'development' ? window.localStorage.getItem('loginPassword') : '',
      captchaKey: '',
      captchaText: '',
      captchaImage: '',
      errorMessage: '',
      isError: false
    }
  },
  computed: {

  },
  methods: {
    async getCaptcha() {
      return await this.$API.user.getCaptcha()
    },
    async loadCaptcha() {
      /*
      const result = await this.getCaptcha()
      if (result.code !== 0) {
        alert(result.msg)
        return
      }

      this.captchaKey = result.key
      this.captchaImage = result.img
       */
    },

    // 회원 로그인
    async tryToLogIn() {
      const loader = this.$loading.show({ container: this.$refs.formLogin })
      try {
        window.localStorage.setItem('loginUsername', this.username)
        if (process.env.NODE_ENV === 'development') {
          window.localStorage.setItem('loginPassword', this.password)
        }
        const result = await this.$API.user.login(this.username, this.password, this.captchaKey, this.captchaText)

        if (result.code !== 0) {
          this.errorMessage = result.msg
          await this.loadCaptcha()
          return
        }

        // store에 저장
        this.$store.commit('auth/SET_CURRENT_USER', result.user)

        // 지정한 시간 동안 액션이 없으면 로그아웃
        this.$store.dispatch('auth/refresh')

        // 원래 요청했던 페이지로 이동
        this.$router.push(
            this.$route.query.redirectFrom || {
              path: "/",
            }
        )
      } catch (e) {
        this.$log.error(e)
        this.errorMessage = e.message
      } finally {
        loader.hide()
      }
    },
  },
  mounted() {
    this.loadCaptcha().then()
  },
}
</script>

<template>
  <Layout>
    <div class="row justify-content-center" style="height: 99%;">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div style="background-color:#000000">
            <div class="row">
              <div class="col-12">
                <div class="p-5 text-center">
                  <img
                      :src="$store.getters['layout/logoImage']"
                      alt
                      class="img-fluid"
                      style="width: 70%;"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0 pt-4" ref="formLogin">
            <b-alert
                v-model="isError"
                variant="danger"
                class="mt-3"
                dismissible
            >{{ errorMessage }}
            </b-alert
            >
            <div
                v-if="errorMessage"
                :class="'alert alert-danger'"
            >
              {{ errorMessage }}
            </div>

            <b-form class="p-2" @submit.prevent="tryToLogIn">
              <b-form-floating-label
                  class="mb-3"
                  id="input-group-1"
                  :label="$t('아이디')"
                  label-for="input-1"
              style="color: #8f9194;">
                <b-form-input
                    id="input-1"
                    v-model="username"
                    type="text"
                    :placeholder="$t('input_username')"
                style="background-color: #000000; color: #ffffff;"></b-form-input>
              </b-form-floating-label>

              <b-form-floating-label
                  class="mb-3"
                  id="input-group-2"
                  :label="$t('비밀번호')"
                  label-for="input-2"
                  style="color: #8f9194;">
                <b-form-input
                    id="input-2"
                    v-model="password"
                    type="password"
                    :placeholder="$t('input_password')"
                    style="background-color: #000000; color: #ffffff;"></b-form-input>
              </b-form-floating-label>
              <!--
              <div class="text-center">
                <span v-html="captchaImage"></span>
              </div>
              <b-form class="p-2" @submit.prevent="tryToLogIn">
                <b-form-floating-label
                    id="input-group-10"
                    :label="$t('보안숫자')"
                    label-for="input-10"
                >
                  <b-form-input
                      id="input-10"
                      v-model="captchaText"
                      type="text"
                      :placeholder="$t('보안숫자')"
                      class="text-center"
                  ></b-form-input>
                </b-form-floating-label>
              </b-form>
              //-->
              <div class="mt-3 d-grid">
                <b-button type="submit" variant="primary" style="background-color: #786c43; color: #ffffff;">{{ $t('로그인') }}</b-button>
              </div>
              <!--
              <div class="mt-4 text-center">
                <h5 class="font-size-14 mb-3">Sign in with</h5>

                <ul class="list-inline">
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0)"
                      class="
                        social-list-item
                        bg-primary
                        text-white
                        border-primary
                      "
                    >
                      <i class="mdi mdi-facebook"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0)"
                      class="social-list-item bg-info text-white border-info"
                    >
                      <i class="mdi mdi-twitter"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0)"
                      class="
                        social-list-item
                        bg-danger
                        text-white
                        border-danger
                      "
                    >
                      <i class="mdi mdi-google"></i>
                    </a>
                  </li>
                </ul>
              </div>
              //-->
              <!--
              <div class="mt-4 text-center">
                <router-link to="/forgot-password" class="text-muted">
                  <i class="mdi mdi-lock me-1"></i> {{ $t('비밀번호 찾기')}}
                </router-link>
              </div>
              //-->
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <!--
          <p>
            Don't have an account ?
            <router-link to="/register" class="fw-medium text-primary"
              >Signup now</router-link
            >
          </p>
          <p>
            © {{ new Date().getFullYear() }} API. Crafted with
            <i class="mdi mdi-heart text-danger"></i> by Henry
          </p>
          //-->
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
